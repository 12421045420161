<template>
	<div class="mru-text">
		<div class="mru-container">
			<fieldset>
				<legend>Resolve MRU</legend>
				<h3>MRU: {{ selectedMru.mruName }}</h3>
				<h4>
					<span :class="Math.sign(selectedMru.toAccountAmountAU) === 1 ? 'cash-in' : 'cash-out'">{{
						Math.sign(selectedMru.toAccountAmountAU) === 1 ? "Cash-in: " : "Cash-out: "
					}}</span>
					{{ toLocaleCurrencyIntl(selectedMru.toAccountAmountAU) }}
				</h4>
				<div class="flex">
					<button @click="commitMRU()" title="" class="btn commit">Commit Transcation</button>
					<button @click="cancelMRU()" title="" class="btn cancel">Cancel Transcation</button>
					<button @click="closeModal()" title="" class="btn">Close Dalog</button>
				</div>
			</fieldset>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
export default {
	name: "ResolveMru",
	props: {
		selectedMru: Object,
	},
	data() {
		return {
			body: document.querySelector("body"),
		};
	},
	created() {
		let thisInstance = this;
		onBeforeUnmount(() => {
			thisInstance.body.onkeydown = null;
		});
	},
	mounted() {
		let thisInstance = this;
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") thisInstance.closeModal();
		};
	},
	methods: {
		returnMruWithPayload(action) {
			let mru = Object.assign({}, this.selectedMru);
			let payload = {
				MruTransferId: mru.id,
				CasinoId: mru.casinoId,
				Action: action,
			};
			mru.payload = payload;
			return mru;
		},
		commitMRU() {
			let confirmCommit = confirm(
				`Are you sure you want to commit ${this.toLocaleCurrencyIntl(
					this.selectedMru.toAccountAmountAU
				)} transaction?\nThis action cannot be undone!`
			);
			if (!confirmCommit) {
				return;
			}
			this.eventBus.emit("resolveMRU", this.returnMruWithPayload("Commit"));
			this.eventBus.emit("closeMruResolveModal");
		},
		cancelMRU() {
			let cancelMru = confirm(
				`Are you sure you want to cancel ${this.toLocaleCurrencyIntl(
					this.selectedMru.toAccountAmountAU
				)} transaction?\nThis action cannot be undone!`
			);
			if (!cancelMru) {
				return;
			}
			this.eventBus.emit("resolveMRU", this.returnMruWithPayload("Cancel"));
			this.eventBus.emit("closeMruResolveModal");
		},
		closeModal() {
			this.eventBus.emit("closeMruResolveModal");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mru-text {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(5px);
	z-index: 100;
}

.mru-container {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #222;
	color: #fff;
	width: 80%;
	margin: auto;
	padding: 30px;
	border-radius: 12px;
	text-align: center;
}

.mru-container fieldset {
	display: flex;
	flex-flow: column;
	padding: 30px;
}

.mru-container button {
	margin: 5px auto;
}

.commit {
	background-color: #008000;
}

.cancel {
	background-color: #f00;
}

.flex {
	display: flex;
	flex-direction: column;
}

.cash-out,
.cash-in {
	font-weight: bold;
}

.cash-out {
	color: #00b8ff;
}

.cash-in {
	color: #00c900;
}

@media (min-width: 768px) {
	.mru-container {
		width: 50%;
	}

	.flex {
		display: flex;
		flex-direction: row;
	}
}
</style>
